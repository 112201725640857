import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Modal, Box, TextField, Button, Typography } from '@mui/material';

const TokenHandler = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const zeendocToken = urlParams.get('Zeendoc_Token');
    const existingEmail = urlParams.get('mail');

    // Ne tente pas de récupérer le token si l'email est déjà présent
    if (existingEmail) {
      setEmail(existingEmail);
      return;
    }

    if (zeendocToken) {
      axios.get(`https://armoires.zeendoc.com/holding_flm/Ihm/Account/Gateway_Check_Token.php?Zeendoc_Token=${zeendocToken}`)
        .then(response => {
          setUserData(response.data);
          if (response.data.Mail) {
            navigate(`?mail=${encodeURIComponent(response.data.Mail)}`);
          }
        })
        .catch(error => {
          setError('Unknown token, your IP has been blacklisted for 10s');
          setIsModalOpen(true); // Ouvre la fenêtre modale seulement en cas d'erreur
        });
    } else {
      setError('No Zeendoc token found');
      setIsModalOpen(true); // Ouvre la fenêtre modale si aucun token n'est trouvé
    }
  }, [navigate]);

  const handleEmailSubmit = () => {
    if (email) {
      setIsModalOpen(false);
      navigate(`?mail=${encodeURIComponent(email)}`);
    }
  };

  return (
    <div>

      {/* Fenêtre modale pour la saisie de l'email */}
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          border: '2px solid #000', 
          boxShadow: 24, 
          p: 4 
        }}>
          <Typography id="modal-title" variant="h6" component="h2">
            Saisir l'adresse email
          </Typography>
          <TextField
            fullWidth
            label="Adresse Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ mt: 2, mb: 2 }}
          />
          <Button variant="contained" color="primary" onClick={handleEmailSubmit}>
            Soumettre
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default TokenHandler;
