import { PDFDocument, rgb } from 'pdf-lib';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { saveAs } from 'file-saver';
import pica from 'pica';
import logopdf from '../assets/logo_europtp.png';

export const generatePDF = async (header, logo, lines, discount, comment, attachments, sigCanvas, totalHT, totalAfterDiscount, totalTVA, totalTTC) => {
  const doc = new jsPDF();

  // Add the logo to the PDF
  const img = new Image();
  img.src = logo;
  console.log(img.src);

  img.onload = () => {
    const aspectRatio = img.width / img.height;
    const newWidth = aspectRatio * 25; // Set height to 25 and adjust width accordingly
    doc.addImage(img, 'PNG', 10, 10, newWidth, 25);

    doc.setFontSize(14);
    doc.setFont('helvetica', 'bold');
    doc.text('BON DE COMMANDE', 185, 20, { align: 'right' });
    doc.rect(130, 10, 60, 18); // Drawing the rectangle for BON DE COMMANDE

    const date = new Date();
    const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;

    doc.setFontSize(10);
    doc.setFont('helvetica', 'normal');
    doc.text(`Titre: ${header.title}`, 20, 50);
    doc.text(`Date: ${formattedDate}`, 20, 60);
    doc.text(`Référence de commande fournisseur: ${header.supplierOrderRef}`, 20, 67);
    doc.text(`Signataire: ${header.signatory}`, 20, 74);

    // Drawing the rectangle for supplier details
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text(`Fournisseur :`, 130, 50);
    doc.text(`${header.supplier}`, 130, 55);

    // Ajouter les colonnes pour la remise
    const tableColumn = ["Désignation", "Quantité", "PU HT", "Remise %", "Prix Total"];
    const tableRows = [];

    lines.forEach(line => {
      const lineData = [
        line.designation,
        line.quantity,
        parseFloat(line.unitPrice).toFixed(2),
        `${parseFloat(line.discount || 0).toFixed(2)} %`,  // Ajout de la remise %
        parseFloat(line.totalPrice).toFixed(2)
      ];
      tableRows.push(lineData);
    });

    autoTable(doc, {
      head: [tableColumn],
      body: tableRows,
      startY: 90,
      columnStyles: {
        0: { cellWidth: 80, halign: 'left' },   // Désignation - align left
        1: { cellWidth: 20, halign: 'center' }, // Quantité - align center
        2: { cellWidth: 30, halign: 'right' },  // PU HT - align right
        3: { cellWidth: 25, halign: 'right' },  // Remise % - align right
        4: { cellWidth: 30, halign: 'right' }   // Prix Total - align right
      },
      headStyles: {
        fillColor: [22, 160, 133],
        textColor: [255, 255, 255],
        fontStyle: 'bold',
      },
      didParseCell: (data) => {
        if (data.section === 'head' && data.row.index === 0) {
          if (data.column.index === 0) {
            data.cell.styles.halign = 'left';
            data.cell.styles.fillColor = [22, 160, 133];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = 'bold';
          } else if (data.column.index >= 1 && data.column.index <= 4) {
            data.cell.styles.halign = 'right';
            data.cell.styles.fillColor = [22, 160, 133];
            data.cell.styles.textColor = [255, 255, 255];
            data.cell.styles.fontStyle = 'bold';
          }
        }
      },
      tableWidth: 'wrap'
    });

    const finalY = doc.lastAutoTable.finalY;

    doc.text('Commentaire:', 20, finalY + 10);
    doc.text(comment, 20, finalY + 20);

    autoTable(doc, {
      head: [['', 'Montant (€)']],
      body: [
        ['Montant HT Avant Remise', totalHT.toFixed(2)],  // Montant HT Avant Remise
        ['Taux de Remise Total', `${(parseFloat(discount) || 0).toFixed(2)} %`],  // Utilisation du taux de remise total
        ['Montant HT', totalAfterDiscount.toFixed(2)],
        ['Montant Total TVA (20%)', totalTVA.toFixed(2)],
        ['Montant Total TTC', totalTTC.toFixed(2)],
      ],
      startY: finalY + 30,
      margin: { left: 130 },
      theme: 'striped',
      headStyles: { fillColor: [22, 160, 133] },
      styles: { halign: 'right' },
    });

    generateSignatureAndAttachments(doc, attachments, sigCanvas, header).then((finalPdfBytes) => {
      saveAs(new Blob([finalPdfBytes], { type: 'application/pdf' }), `commande_${header.supplier}_${formattedDate}.pdf`);
    });
  };
};

const generateSignatureAndAttachments = async (doc, attachments, sigCanvas, header) => {
  const pdfBytes = await doc.output('arraybuffer');
  let finalDoc = await PDFDocument.load(pdfBytes);

  const signatureImage = sigCanvas.current.getTrimmedCanvas({ willReadFrequently: true }).toDataURL('image/png');
  const signatureImageBytes = await fetch(signatureImage).then(res => res.arrayBuffer());
  const signatureImageEmbed = await finalDoc.embedPng(signatureImageBytes);

  const pages = finalDoc.getPages();
  const lastPage = pages[pages.length - 1];

  const signatureY = lastPage.getHeight() - 750;
  const signatureText = `Signature du demandeur : ${header.signatory}`;

  lastPage.drawText(signatureText, {
    x: 40,
    y: signatureY + 90,
    size: 10
  });

  lastPage.drawImage(signatureImageEmbed, {
    x: 40,
    y: signatureY,
    width: 120,
    height: 70
  });

  lastPage.drawRectangle({
    x: 35,
    y: signatureY - 20,
    width: 170,
    height: 90,
    borderColor: rgb(0, 0, 0),
    borderWidth: 1,
  });

  for (let attachment of attachments) {
    if (attachment.file) {
      const { file } = attachment;
      const fileType = file.type;

      if (fileType === 'application/pdf') {
        const attachmentBytes = await file.arrayBuffer();
        const attachmentDoc = await PDFDocument.load(attachmentBytes);
        const copiedPages = await finalDoc.copyPages(attachmentDoc, attachmentDoc.getPageIndices());
        copiedPages.forEach(page => finalDoc.addPage(page));
      } else if (fileType === 'image/jpeg') {
        const imgBlob = await resizeImage(file, 595.28 - 20, 841.89 - 20);
        const imgBytes = await imgBlob.arrayBuffer();
        const img = await finalDoc.embedJpg(imgBytes);
        const page = finalDoc.addPage([595.28, 841.89]);
        page.drawImage(img, {
          x: (595.28 - img.width) / 2,
          y: (841.89 - img.height) / 2,
          width: img.width,
          height: img.height
        });
      }
    }
  }

  return await finalDoc.save();
};

const resizeImage = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = img.width * ratio;
      const height = img.height * ratio;

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      pica().resize(img, canvas, {
        quality: 3,
      }).then(() => {
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', 0.8);
      }).catch(reject);
    };

    img.onerror = reject;
  });
};
