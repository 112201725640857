import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import EuropTPForm from './pages/EuropTPForm';
import EuropAcroForm from './pages/EuropAcroForm';
import LCMetallerieForm from './pages/LCMetallerieForm';
import ETEForm from './pages/ETEForm';
import LombartForm from './pages/LombartForm';
import Navigation from './components/Navigation';
import TokenHandler from './TokenHandler'; // Assure-toi que l'importation soit correcte
import './App.css';

function App() {
  return (
    <Router>
      <div>
        <Navigation />
        <TokenHandler /> {/* Ajoute TokenHandler ici pour qu'il se lance au démarrage */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/europ-tp" element={<EuropTPForm />} />
          <Route path="/europ-acro" element={<EuropAcroForm />} />
          <Route path="/lc-metallerie" element={<LCMetallerieForm />} />
          <Route path="/ete" element={<ETEForm />} />
          <Route path="/lombart" element={<LombartForm />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;