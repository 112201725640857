import React from 'react';
import OrderForm from '../components/OrderForm';
import logo from '../assets/logo_lc_metallerie.png';

const LCMetallerieForm = () => {
  return (
    <div>
      <OrderForm logo={logo} title="LC METALLERIE" />
    </div>
  );
};

export default LCMetallerieForm;
