import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TextField, Button, Typography, Container, Box, Grid, InputAdornment, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Alert } from '@mui/material';
import OrderLines from './OrderLines';
import Attachments from './Attachments';
import Signature from './Signature';
import { generatePDF } from './PDFGenerator';
import authorizedEmails from '../components/authorizedEmails.json'; // Importer le fichier JSON des emails autorisés
import './OrderForm.css'; // Import CSS

const SectionTitle = ({ title }) => (
  <Box display="flex" alignItems="center" mt={4} mb={2}>
    <Divider style={{ flexGrow: 1 }} />
    <Typography variant="h6" style={{ margin: '0 20px', whiteSpace: 'nowrap' }}>{title}</Typography>
    <Divider style={{ flexGrow: 1 }} />
  </Box>
);

const OrderForm = ({ logo, title }) => {
  const location = useLocation(); // Utilisez useLocation pour accéder à l'URL actuelle
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('mail') || ''; // Récupérer l'adresse email depuis l'URL
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    // Vérifier si l'adresse e-mail est dans la liste des adresses autorisées
    if (authorizedEmails.authorizedEmails.includes(email)) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
      setErrorMessage('Accès non autorisé');
    }
  }, [email]);

  const [header, setHeader] = useState({
    title: '',
    supplier: '',
    projectNumber: '',
    supplierOrderRef: '',
    signatory: email, // Initialiser le champ signatory avec l'adresse email
  });

  const [lines, setLines] = useState([
    { designation: '', quantity: '', unitPrice: '', totalPrice: '0.00' }
  ]);

  const [discount, setDiscount] = useState(0);
  const [comment, setComment] = useState('');
  const [attachments, setAttachments] = useState([{ file: null, type: '' }]);
  const [fileErrors, setFileErrors] = useState([]);
  const [totalHT, setTotalHT] = useState(0); // Nouveau state pour le montant HT avant remise
  const [totalAfterDiscount, setTotalAfterDiscount] = useState(0);
  const [totalTVA, setTotalTVA] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const sigCanvas = useRef(null);
  const [signatureError, setSignatureError] = useState(false);

  const handleHeaderChange = (e) => {
    const { name, value } = e.target;
    setHeader({
      ...header,
      [name]: value,
    });
  };

  const handleLineChange = (index, e) => {
    const { name, value } = e.target;
    const newLines = [...lines];
    newLines[index][name] = value;
    if (name === 'quantity' || name === 'unitPrice') {
      newLines[index].totalPrice = (newLines[index].quantity * newLines[index].unitPrice).toFixed(2);
    }
    setLines(newLines);
  };

  const handleAddLine = () => {
    setLines([...lines, { designation: '', quantity: '', unitPrice: '', totalPrice: '0.00' }]);
  };

  const handleRemoveLine = (index) => {
    const newLines = lines.filter((_, i) => i !== index);
    setLines(newLines);
  };

  const handleDiscountChange = (e) => {
    setDiscount(e.target.value);
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };

  useEffect(() => {
    const totalHTValue = lines.reduce((sum, line) => sum + parseFloat(line.totalPrice), 0); // Calculer le montant HT avant remise
    const discountValue = (totalHTValue * discount) / 100;
    const totalAfterDiscountValue = totalHTValue - discountValue;
    const totalTVAValue = totalAfterDiscountValue * 0.2;
    const totalTTCValue = totalAfterDiscountValue + totalTVAValue;

    setTotalHT(totalHTValue); // Mettre à jour le montant HT avant remise
    setTotalAfterDiscount(totalAfterDiscountValue);
    setTotalTVA(totalTVAValue);
    setTotalTTC(totalTTCValue);
  }, [lines, discount]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (sigCanvas.current.isEmpty()) {
      setSignatureError(true);
      return;
    }

    setSignatureError(false);
    await generatePDF(header, logo, lines, discount, comment, attachments, sigCanvas, totalHT, totalAfterDiscount, totalTVA, totalTTC);
  };

  if (!isAuthorized) {
    return (
      <Container>
        <Box mt={5} mb={5} textAlign="center">
          <Alert severity="error">{errorMessage}</Alert>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={5} mb={5}>
        <img src={logo} alt={`${title} Logo`} className="logo" />
      </Box>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        Créer une Commande - {title}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Titre de la Demande"
              name="title"
              value={header.title}
              onChange={handleHeaderChange}
              fullWidth
              InputProps={{ style: { textAlign: 'center' } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Fournisseur"
              name="supplier"
              value={header.supplier}
              onChange={handleHeaderChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Numéro de Chantier"
              name="projectNumber"
              value={header.projectNumber}
              onChange={handleHeaderChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Référence de Commande Fournisseur"
              name="supplierOrderRef"
              value={header.supplierOrderRef}
              onChange={handleHeaderChange}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Signataire"
              name="signatory"
              value={header.signatory}
              onChange={handleHeaderChange}
              fullWidth
              required
              InputProps={{
                readOnly: true,
                style: { backgroundColor: '#f0f0f0' } // Griser le champ pour indiquer qu'il est non modifiable
              }}
            />
          </Grid>
        </Grid>
        
        <SectionTitle title="Lignes de Produit" />
        <OrderLines 
          lines={lines} 
          handleLineChange={handleLineChange} 
          handleAddLine={handleAddLine} 
          handleRemoveLine={handleRemoveLine} 
        />
        
        <Box mt={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <TextField
            label="Remise total en %"
            name="discount"
            type="number"
            value={discount}
            onChange={handleDiscountChange}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            style={{ width: '25%' }}
            fullWidth={window.innerWidth < 600}
          />
        </Box>
        
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} style={{ marginLeft: 'auto' }}>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#1976d2' }}>
                    <TableCell></TableCell>
                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white', textAlign: 'center' }}>Montant (€)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Montant HT Avant Remise</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{totalHT.toFixed(2)} €</TableCell> {/* Montant HT avant remise */}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Montant HT Après Remise</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{totalAfterDiscount.toFixed(2)} €</TableCell> {/* Montant HT après remise */}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Montant Total TVA (20%)</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{totalTVA.toFixed(2)} €</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ backgroundColor: '#1976d2', color: 'white' }}>Montant Total TTC</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{totalTTC.toFixed(2)} €</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        
        <Box mt={2}>
          <TextField
            label="Commentaire"
            name="comment"
            value={comment}
            onChange={handleCommentChange}
            fullWidth
            multiline
            rows={4}
          />
        </Box>
        
        <SectionTitle title="Pièces Jointes" />
        <Attachments 
          attachments={attachments} 
          setAttachments={setAttachments} 
          fileErrors={fileErrors} 
          setFileErrors={setFileErrors} 
        />
        
        <SectionTitle title="Signature" />
        <Signature sigCanvas={sigCanvas} signatureError={signatureError} clearSignature={() => sigCanvas.current.clear()} setSignatureError={setSignatureError} />
        
        <Box mt={2} style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Soumettre
          </Button>
        </Box>
        <Box mb={4}></Box>
      </form>
    </Container>
  );
};

export default OrderForm;
