import React from 'react';
import OrderForm from '../components/OrderForm';
import logo from '../assets/logo_ete.png';

const ETEForm = () => {
  return (
    <div>
      <OrderForm logo={logo} title="ETE" />
    </div>
  );
};

export default ETEForm;
