// HomePage.js

import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Button, Typography, Grid, Alert, Modal, TextField } from '@mui/material';
import authorizedEmails from '../components/authorizedEmails.json';

const HomePage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('mail') || '';

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inputEmail, setInputEmail] = useState('');

  useEffect(() => {
    if (authorizedEmails.authorizedEmails.includes(email)) {
      setIsAuthorized(true);
    } else {
      setIsAuthorized(false);
      setErrorMessage('Accès non autorisé');
    }

    setLoading(false);
  }, [email]);

  // Generate links including existing query parameters
  const generateLink = (path) => `${path}${location.search}`;

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleEmailSubmit = () => {
    if (inputEmail) {
      navigate(`?mail=${encodeURIComponent(inputEmail)}`);
      setIsModalOpen(false);
    }
  };

  if (loading) {
    return (
      <Container>
        <Box mt={5} mb={5} textAlign="center">
          <Typography variant="h6">Chargement...</Typography>
        </Box>
      </Container>
    );
  }

  if (!isAuthorized) {
    return (
      <Container>
        <Box mt={5} mb={5} textAlign="center">
          <Alert severity="error">{errorMessage}</Alert>
          <Button variant="contained" color="primary" onClick={handleOpenModal} sx={{ mt: 2 }}>
            Connexion
          </Button>
        </Box>

        {/* Fenêtre modale pour la saisie de l'email */}
        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{ 
            position: 'absolute', 
            top: '50%', 
            left: '50%', 
            transform: 'translate(-50%, -50%)', 
            width: 400, 
            bgcolor: 'background.paper', 
            border: '2px solid #000', 
            boxShadow: 24, 
            p: 4 
          }}>
            <Typography id="modal-title" variant="h6" component="h2">
              Saisir l'adresse email
            </Typography>
            <TextField
              fullWidth
              label="Adresse Email"
              variant="outlined"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
              sx={{ mt: 2, mb: 2 }}
            />
            <Button variant="contained" color="primary" onClick={handleEmailSubmit}>
              Soumettre
            </Button>
          </Box>
        </Modal>
      </Container>
    );
  }

  return (
    <Container>
      <Box mt={5} mb={5} textAlign="center">
        <Typography variant="h4" component="h1" gutterBottom>
          Bienvenue sur la Page de Commande
        </Typography>
        <Typography variant="h6" component="h2" gutterBottom>
          Connecté en tant que : {email}
        </Typography>
        <Box mt={3}>
          <Grid container spacing={3} direction="column" alignItems="center">
            <Grid item xs={12} sm={12} md={4} container justifyContent="center">
              <Button
                component={Link}
                to={generateLink('/europ-tp')}
                variant="contained"
                color="primary"
                fullWidth
                style={{ maxWidth: '75%' }}
              >
                EUROP TP
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} container justifyContent="center">
              <Button
                component={Link}
                to={generateLink('/europ-acro')}
                variant="contained"
                color="primary"
                fullWidth
                style={{ maxWidth: '75%' }}
              >
                EUROP ACRO
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} container justifyContent="center">
              <Button
                component={Link}
                to={generateLink('/lc-metallerie')}
                variant="contained"
                color="primary"
                fullWidth
                style={{ maxWidth: '75%' }}
              >
                LC METALLERIE
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} container justifyContent="center">
              <Button
                component={Link}
                to={generateLink('/ete')}
                variant="contained"
                color="primary"
                fullWidth
                style={{ maxWidth: '75%' }}
              >
                ETE
              </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={4} container justifyContent="center">
              <Button
                component={Link}
                to={generateLink('/lombart')}
                variant="contained"
                color="primary"
                fullWidth
                style={{ maxWidth: '75%' }}
              >
                LOMBART
              </Button>
            </Grid>
            {/* Ajoutez les autres boutons de la même manière */}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default HomePage;
